.container {
  max-width: 1140px;
  margin: auto;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #26272b; */
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #18191b;
  /* box-shadow: 0px 0px 12px #ffffff; */
  border-radius: 8px;
  margin: 2rem 1rem;
  padding: 0.7rem 1rem;
  font-weight: 700;
}

.coin-name {
  margin-left: -4rem;
}

.coin-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #26272b; */
  background-color: #ffffff;
  /* background-color: #132f4b; */

  box-shadow: 0px 0px 12px #18191b;
  border-radius: 8px;
  margin: 2rem 1rem;
  padding: 0.7rem 1rem;
}

.coin-row:hover {
  transform: scale(1.04);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

img {
  height: 40px;
  margin-right: 8px;
  width: auto;
}

.img-symbol {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .hide-mobile {
    display: none;
  }
}
