.App {
  text-align: center; 
  font-family: 'IBM Plex Sans', sans-serif;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
}
/* style */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dark {
  background-color: hsl(0, 0%, 0%);
  /* color: #fff; */
}
.light {
  /* background-color: hsl(252, 70%, 77%); */
  background-color: hsl(252, 39%, 43%);
  /* color: #333; */
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
font-family: 'IBM Plex Sans Condensed', sans-serif;
  /* background-image: ("BlogApp/Backend/blogimage/1680341299789.png"); */
  /* background-color: #a95050; */
  /* background-image: url(https://www.transparenttextures.com/patterns/cubes.png); */
  /* background-image: url(https://wallpaper-house.com/data/out/8/wallpaper2you_237884.jpg); */
  /* background-color: #002933; */
  /* background-image: url(https://www.transparenttextures.com/patterns/brushed-alum.png); */
  /* padding: 0 16px; */
}

html {
  font-family: 'IBM Plex Sans', sans-serif;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
}
footer {
  /* position: sticky; */
  /* position: fixed; */
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
footer p {
  color: #4b4747;
}
/* end */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
